<template>
  <div>
    <b-row class="match-height">
      <b-col cols="4">
        <b-row class="match-height">
          <b-col cols="6">
            <ecommerce-order-chart
              :data="last5daysOrders.data"
              :amount="last5daysOrders.total"
            />
          </b-col>
          <b-col cols="6">
            <ecommerce-profit-chart
              :data="last7dayLeads.data"
              :amount="last7dayLeads.total"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8">
        <ecommerce-statistics :data="statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="4">
        <low-in-stock-card
          @paginate="paginate"
          title="Low In Stock"
          :data="lowInStockItems"
          :rows="rows.lowInStock"
          :perPage="perPage"
        />
      </b-col>
      <b-col cols="4">
        <repairs-due-today-card
          @paginate="paginate"
          :rows="rows.repairsDueToday"
          title="Repairs Due Today"
          :data="repairsDueToday"
          :perPage="perPage"
          component="repairsDueToday"
          icon="ToolIcon"
        />
      </b-col>
      <b-col cols="4">
        <repairs-listing-card
          @paginate="paginate"
          :rows="rows.onsiteRepairsToday"
          title="Onsite Repairs Today"
          :data="onsiteRepairsToday"
          :perPage="perPage"
          component="onsiteRepairsToday"
          icon="HomeIcon"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from "bootstrap-vue";
import EcommerceOrderChart from "@/components/EcommerceOrderChart.vue";
import EcommerceStatistics from "@/components/EcommerceStatistics.vue";
import EcommerceProfitChart from "@/components/EcommerceProfitChart.vue";
import ListingCard from "@/components/ListingCard.vue";
import RepairsListingCard from "@/components/RepairsListingCard.vue";
import RepairsDueTodayCard from "@/components/RepairsDueTodayCard.vue";
import LowInStockCard from "@/components/LowInStockCard.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    EcommerceOrderChart,
    EcommerceStatistics,
    EcommerceProfitChart,
    ListingCard,
    LowInStockCard,
    RepairsListingCard,
    RepairsDueTodayCard,
  },
  data() {
    return {
      last5daysOrders: {
        total: 0,
        data: [
          {
            name: "Last 5 days",
            data: [45, 85, 65, 45, 65],
          },
        ],
      },
      last7dayLeads: {
        total: 6199,
        data: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
      },
      statisticsItems: [],
      transactionData: [
        {
          mode: "Wallet",
          types: "Starbucks",
          avatar: "CpuIcon",
          avatarVariant: "light-primary",
          payment: "-$74",
          deduction: true,
        },
        {
          mode: "Bank Transfer",
          types: "Add Money",
          avatar: "HomeIcon",
          avatarVariant: "light-success",
          payment: "+$480",
          deduction: false,
        },
        {
          mode: "Paypal",
          types: "Add Money",
          avatar: "ToolIcon",
          avatarVariant: "light-danger",
          payment: "+$480",
          deduction: false,
        },
        {
          mode: "Mastercard",
          types: "Ordered Food",
          avatar: "CreditCardIcon",
          avatarVariant: "light-warning",
          payment: "-$23",
          deduction: true,
        },
        {
          mode: "Transfer",
          types: "Refund",
          avatar: "TrendingUpIcon",
          avatarVariant: "light-info",
          payment: "+$98",
          deduction: false,
        },
      ],
      lowInStockItems: [],
      onsiteRepairsToday: [],
      repairsDueToday: [],
      page: {
        lowInStock: 1,
        onsiteRepairsToday: 1,
        repairsDueToday: 1,
      },
      rows: {
        lowInStock: 0,
        onsiteRepairsToday: 0,
        repairsDueToday: 0,
      },
      perPage: 5,
    };
  },
  methods: {
    getDashboardData() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/dashboard/widget-data?low-in-stock-page=${this.page.lowInStock}&onsite-page=${this.page.onsiteRepairsToday}&due-today-page=${this.page.repairsDueToday}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.statisticsItems = response.data.statiticsData;
          this.last5daysOrders = response.data.last5daysOrders;
          this.last7dayLeads = response.data.last7dayLeads;
          this.lowInStockItems = response.data.lowInStock.inventories;
          this.onsiteRepairsToday = response.data.onsiteRepairsToday.orders;
          this.repairsDueToday = response.data.dueTodayOrders.orders;

          this.rows.lowInStock = response.data.lowInStock.totalCount;
          this.rows.onsiteRepairsToday =
            response.data.onsiteRepairsToday.totalCount;
          this.rows.repairsDueToday = response.data.dueTodayOrders.totalCount;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    paginate(component, value) {
      this.page[component] = value;
      this.getDashboardData();
    },
  },
  mounted() {
    this.getDashboardData();
  },
};
</script>

<style></style>
