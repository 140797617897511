<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="/inventory-management?low-in-stock=true">
          View All
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-link
        v-for="transaction in data"
        :key="transaction._id"
        :to="`/job-sheets/${transaction._id}`"
      >
        <div class="transaction-item mb-1">
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" variant="light-danger">
                <feather-icon size="18" :icon="icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ transaction.mode }}
              </h6>
              <small
                >{{ transaction.brand.name }} /
                {{ transaction.model.name }}
              </small>
            </b-media-body>
          </b-media>
          <!-- <div class="font-weight-bolder">
            {{ transaction.address.area }}
          </div> -->
        </div>
      </b-link>
    </b-card-body>

    <b-card-footer>
      <b-pagination
        :perPage="perPage"
        v-model="currentPage"
        :total-rows="rows"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BBadge,
  BLink,
  BCardFooter,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BCardFooter,
    BPagination,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
    },
    rows: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    component: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  watch: {
    currentPage(newValue) {
      this.$emit("paginate", this.component, newValue);
    },
  },
};
</script>
